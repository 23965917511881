import React, { useState, useEffect } from "react"
import axios from "axios"

const sendMail = async (email, firstName, fullName, phone) => {
  try {
    await axios({
      method: "post",
      url: "https://metodoesbelta.com/api/activecampaign/saveSqueezeLead.php",
      // url: 'https://b9b8-2804-7f4-c783-7ba6-983b-97d9-72b8-9460.sa.ngrok.io/demo/sendmail.php',
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        email: email,
        firstName: firstName,
        name: fullName,
        phone: phone,
      }),
    })
    return {
      result: true,
      message: "Tudo Certo! Os Resultados Foram Enviados Para o Seu Email ",
    }
  } catch (err) {
    console.error(err)
    return {
      result: false,
      message: "Não foi possivel enviar para seu email neste momento.",
    }
  }
}

export { sendMail }
