import styled from "styled-components"
import media from "styled-media-query"

export const Layout = styled.main`
  display: grid;
  grid-template-columns: minmax(1em, 1fr) repeat(3, 1fr) minmax(1em, 1fr);
  /* overflow: hidden;
    position: absolute; */
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: #5b2266;
  background: linear-gradient(
    0deg,
    rgba(91, 34, 102, 1) 0%,
    rgba(102, 34, 96, 1) 35%,
    rgba(200, 43, 112, 1) 100%
  );

  .coverImage {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
`

export const Body = styled.section`
  grid-row: 2/4;
  grid-column: 1/-1;
  margin: 0 auto;
`

export const Logo = styled.div`
  grid-row: 1/2;
  grid-column: 3/4;
  height: auto;
  width: 10rem;
  margin: 2rem auto;

  ${media.lessThan("large")`
        width: 6rem;
    `}

  &:hover {
    transform: scale(1.05);
    transition: transform 150ms ease-in-out;
  }
`

export const SqueezeWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  font-family: "Roboto", sans-serif;
`

export const SqueezeHeadline = styled.div`
  font-size: 2.2rem;
  margin: 2rem;
  font-weight: 700;
  line-height: 1.1;
  color: white;
  text-align: center;
`

export const SqueezeSubHeadline = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5;
  color: #fafafa;
`

export const SqueezeMagnetDetails = styled.div`
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: white;
`

export const SqueezeImage = styled.img`
  height: auto;
  width: 90%;
`

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  border: 1px solid var(--orange);
  padding: 1rem;
`

export const FormTitle = styled.div`
  font-size: 1.3rem;
  color: var(--purple);
  text-align: center;
  max-width: 25ch;
`

export const FormInput = styled.div`
  position: relative;
  display: inline-block;
  margin: 5px 0;

  .icon {
    height: 25px;
    width: 25px;
    color: var(--orange);
    margin-right: 10px;
  }

  input {
    line-height: 25px;
    background: transparent;
    border: none;
    font-size: 18px;
    border-bottom: 1px solid var(--orange);
    padding: 5px 16px;
    color: var(--postColor);
    border-radius: 0;
    width: 250px;
  }

  input::placeholder {
    color: #999;
  }

  input:hover,
  input:active {
    border: 1px solid var(--orange);
  }
`

export const FormButton = styled.div`
  position: relative;
  display: inline-block;
  margin: 5px 0;
  align-self: center;

  button {
    margin: 2rem auto;
    padding: 1rem 2rem;
    border-radius: 20px;
    color: white;
    width: 250px;
    text-transform: uppercase;
    font-size: 1.1rem;
    font-weight: 500;
    border: 1px solid var(--pink);
    background-color: var(--purple);
    cursor: pointer;
    box-shadow: 2px 2px 14px rgb(0 0 0 / 40%);
    transition: background-color 1s;
  }

  button:hover {
    background-color: var(--pink);
  }

  .icon {
    height: 25px;
    width: 25px;
    color: var(--orange);
    margin-left: 10px;
  }
`
