import React from "react"
import { Layout, Logo, Body } from "./styled"
import logo from "../../images/logo02.png"

const SqueezeLayout = ({ children }) => (
  <Layout>
    <Logo>
      <img src={logo} alt="logo" />
    </Logo>
    <Body>{children}</Body>
  </Layout>
)

export default SqueezeLayout
