import React from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import styled from 'styled-components';
import media from 'styled-media-query';

import * as C from '../styles/elements';

export const AboutSection = styled.section`
    grid-row: 5/6;
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: minmax(1em, 0.5fr) repeat(2, minmax(300px,1fr)) minmax(1em, 0.5fr);
    grid-gap: 1rem;
    background: rgb(91,34,102);
    background: linear-gradient(183deg, rgba(91,34,102,1) 0%, rgba(102,34,96,1) 35%, rgba(200,43,112,1) 100%);
    background-size: cover;
    
    ${media.lessThan("medium")`
        grid-column: 1/-1;
        grid-template-columns: 1fr;
    `}
`

const AboutImage = styled.div`
    align-self: center;
    /* height: 100%; */
    margin: auto;
    grid-column: 2/3;
    ${media.lessThan("large")`
        padding: 2vmin 4vmin;
        grid-column: 1/-1;
    `}
`

const AboutText = styled.div`
    align-self: center;
    background: var(--white);
    padding: 1rem;
    grid-column: 3/4;

    ${media.lessThan("large")`
        grid-column: 1/-1;
        max-width: 60ch;
        margin: 1rem auto;
    `}
`



const About = () => (
    <AboutSection>
        <AboutImage>
            <StaticImage 
                src='../images/char-nutri.jpg'
                // rounded={true}
                alt="Nutri Charline Tormen"
                // height="20vw"
                style={{ 
                    borderRadius: '50%'
                }}/>
        </AboutImage>
        <AboutText>
            <C.Title><C.Color color="var(--purple)">Eu Serei Sua Guia Rumo Ao Emagrecimento Saudável e Prazeroso</C.Color><C.Divider /></C.Title>
            <div style={{paddingLeft: '1rem'}}>
                <C.Paragraph>Olá, me chamo <C.Underline>Charline Tormen.</C.Underline> <br/>
                    Sou Mãe, Esposa, <C.Bolder>Nutricionista Especialista em Nutrição 
                    Esportiva e Emagrecimento há mais de 10 anos</C.Bolder>, Palestrante e Atleta de Fisiculturismo.
                </C.Paragraph>
                <C.Paragraph>Através da alimentação prazerosa e saudável, <C.Underline><C.BG>já ajudei mais de 23.135 pessoas</C.BG></C.Underline> a conquistarem o corpo que sonhavam, a saúde que precisavam e a confiança que mereciam ter.</C.Paragraph>
            </div>
        </AboutText>  
    </AboutSection>
)


export default About;