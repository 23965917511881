import React from "react"
import SqueezeForm from "./SqueezeForm.js"
import About from "../About"
import {
  SqueezeWrapper,
  SqueezeHeadline,
  SqueezeSubHeadline,
  SqueezeMagnetDetails,
  SqueezeImage,
} from "./styled"
import { sendMail } from "./util/squeezeHooks"
import ebook from "../../images/capaslivros3.png"

import styled from "styled-components"
// import media from "styled-media-query";

const Headline = styled(SqueezeHeadline)`
  grid-row: 1/2;
  grid-column: 2/-2;
`

const EbookDetails = styled(SqueezeMagnetDetails)`
  grid-row: 2/3;
  grid-column: 2/4;
`

const FormStyled = styled.div`
  grid-row: 3/4;
  grid-column: 2/4;
`

const EbookImage = styled(SqueezeImage)`
  grid-row: 2/4;
  grid-column: 4/-1;
  margin: auto;
`

const SqueezeAbout = styled.section`
  grid-row: 4/5;
  grid-column: 1/-1;
`

const Receitas = () => {
  const setValues = async (nome, celular, email) => {
    console.log(nome, celular, email)
    let phone = celular ? "+55" + celular : ""
    let name = nome.split(" ")
    let firstName = name[0]
    let fullName = nome
    try {
      const response = await sendMail(email, firstName, fullName, phone)
      // setSentMessage(response)
    } catch (err) {
      // setSentMessage(err)
    }
  }

  return (
    <SqueezeWrapper>
      <Headline>
        10 Receitas Saudáveis, Deliciosas e Calculadas!
        <SqueezeSubHeadline>E de graça!</SqueezeSubHeadline>
      </Headline>
      <EbookImage src={ebook} alt="ebook" />
      <EbookDetails>
        Um ebook gratuito com 10 receitas simples, saudáveis e muito gostosas,
        que você poderá incluir na sua dieta sem medo e sem peso na consciência.
      </EbookDetails>
      <FormStyled>
        <SqueezeForm setValues={setValues} />
      </FormStyled>
      <SqueezeAbout>
        <About />
      </SqueezeAbout>
    </SqueezeWrapper>
  )
}

export default Receitas
