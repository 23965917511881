import React, { useState } from "react"
import { FormTitle, FormButton, FormInput, FormWrapper } from "./styled"
import { Email } from "@styled-icons/entypo/Email"
import { Person, Phone } from "styled-icons/fluentui-system-filled"
import { Download } from "@styled-icons/entypo/Download"

const SqueezeForm = ({ setValues }) => {
  const [email, setEmail] = useState("")
  const [nome, setNome] = useState("")
  const [celular, setCelular] = useState("")

  return (
    <FormWrapper style={{ alignItems: "center" }}>
      <FormTitle>Informe Seus Dados Para Receber seu Ebook</FormTitle>
      <FormInput style={{ marginTop: "2rem" }}>
        <Person className="icon" />
        <input
          type="text"
          min="10"
          max="340"
          placeholder="Seu Nome"
          name="nome"
          value={nome}
          onChange={e => setNome(e.target.value)}
        />
      </FormInput>
      <FormInput style={{ marginTop: "2rem" }}>
        <Phone className="icon" />
        <input
          type="text"
          min="10"
          max="340"
          placeholder="Nº Celular"
          name="celular"
          value={celular}
          onChange={e => setCelular(e.target.value)}
        />
      </FormInput>
      <FormInput style={{ marginTop: "2rem" }}>
        <Email className="icon" />
        <input
          type="email"
          min="10"
          max="340"
          placeholder="Seu melhor Email"
          name="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </FormInput>
      <FormButton>
        <button
          className="primary"
          onClick={e => {
            e.preventDefault()
            setValues({ nome: nome, celular: celular, email: email })
          }}
        >
          Baixar Ebook
          <Download className="icon" />
        </button>
      </FormButton>
    </FormWrapper>
  )
}

export default SqueezeForm
