import React from "react"
import GlobalStyles from "../styles/global"
import SqueezeLayout from "../components/Squeeze/SqueezeLayout"
import Receitas from "../components/Squeeze/Receitas"

const EbookReceitasSqueeze = () => (
  <SqueezeLayout>
    <GlobalStyles />
    <Receitas />
  </SqueezeLayout>
)

export default EbookReceitasSqueeze
